<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  created() {
    const browserLocale = navigator.language.substring(0, 2) || navigator.userLanguage.substring(0, 2)
    this.$i18n.locale = Object.keys(this.$i18n.messages).find(key => key === browserLocale) || this.$i18n.fallbackLocale
  },
}
</script>
